@import '../../App.scss';

.contact {
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 0;

  &.light {
    background: linear-gradient($secondary, $primaryDark);
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;

    h1 {
      font-size: 4rem;
      margin-bottom: 5rem;
      line-height: 4rem;
      color: $primary;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      -webkit-box-reflect: below 1px linear-gradient(transparent, #0007);
      outline: none;
      animation: animate 5s linear infinite;
    }

    @keyframes animate {

      0%,
      18%,
      20%,
      40.1%,
      60%,
      65.1%,
      80%,
      90.1%,
      92% {
        color: rgb(31, 54, 93);
        box-shadow: none;
      }

      18.1%,
      20.1%,
      40%,
      60.1%,
      65%,
      80.1%,
      90%,
      92.1%,
      100% {
        color: #fff;
        text-shadow: 0 0 10px lightskyblue, 0 0 20px lightskyblue,
          0 0 40px lightskyblue, 0 0 80px lightskyblue, 0 0 160px lightskyblue;
      }
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-bottom: 2rem;

      a {
        color: white;
        font-size: 1.6rem;
        text-decoration: none;
        font-weight: 500;
        &:hover {
          color: $secondary;
          text-decoration: underline;
        }
      }


      button {
        color: lightgrey;
        background: none;
        border: none;
        cursor: pointer;
        z-index: 1;
        display: inline-block;
      }
    }



    p {
      color: white;
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 2rem;
    }

    .social-media{
      a{
        color: white;
        font-size: 1.6rem;
        text-decoration: none;
        font-weight: 500;
        margin: 0 0.5rem;
      }
    }
  }

}