@import '../../App.scss';
// Path: src/pages/home/Works.js

.workpage {
  background: linear-gradient($primaryDark, black);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  overflow: hidden;
  gap: 2rem;
  margin: 0;

  &.light {
    background: linear-gradient($secondaryLight, $secondary);
    color: $primaryDark;

    .container {
      ul {
        li {
          &:hover {
            background-color: $secondary;
          }

          &.active {
            background-color: $secondaryDark;
          }
        }
      }

      .works {
        .work {
          border: 1px solid white;

          .info {
            h3 {
              background-color: rgba($color: $secondaryLight, $alpha: 0.7);
            }

            p {
              background-color: rgba($color: $secondaryLight, $alpha: 0.7);
            }
          }
        }
      }
    }
  }


  .header-skills {
    flex: 1;
    padding-left: 5rem;
    min-width: 300px;

    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    gap: 2rem;

    .header {

      h2 {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.7rem;
      }
    }

    .skills {
      display: flex;
      justify-content: center;
      align-items: left;
      flex-direction: column;

      h3 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 1rem;
      }

      p{
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.7rem;
      }
    }
  }

  .container {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      margin-bottom: 2rem;

      li {
        list-style: none;
        cursor: pointer;
        font-size: .8rem;
        font-weight: 600;
        color: inherit;
        transition: all 0.3s ease;
        padding: 0.5rem 0.8rem;
        border-radius: 1rem;

        &:hover {
          background-color: $primary;
        }

        &.active {
          background-color: $primaryLight;
        }
      }
    }

    .works {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      min-width: 550px;
      max-width: 550px;
      min-height: 450px;
      border-radius: 20px;
      transition: all 0.7s ease-in-out;

      .work {
        width: 250px;
        height: 200px;
        border-radius: 20px;
        margin: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        position: relative;
        transition: all 0.5s ease;
        cursor: pointer;
        overflow: hidden;
        border: 1px solid lightgrey;


        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 0;
        }

        .info {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 1rem;

          h3 {
            font-size: 1.3rem;
            font-weight: 600;
            text-align: left;
            padding: 0 1rem;
            line-height: 3rem;
            position: absolute;
            height: 2.5rem;
            width: 100%;
            top: 0;
            left: 0;
            background-color: rgba($color: $primaryDark, $alpha: 0.7)
          }

          p {
            font-size: .8rem;
            font-weight: 400;
            line-height: 1.25rem;
            text-align: justify;
            position: absolute;
            padding: 1rem;
            top: 2.5rem;
            left: 0;
            width: 100%;
            height: 100%;
            display: none;
          }
        }

        &:hover {
          transform: scale(1.1);

          p {
            background-color: rgba($color: $primaryDark, $alpha: 0.7);
            display: block;
          }

        }
      }
    }
  }
}