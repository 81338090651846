@import '../../App.scss';

.hero {
  margin: 0;
    padding: 5rem;
    // background: linear-gradient($primaryDark 0, $primaryDark 8%, black);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;

    .rotatingSun-container {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient($primaryDark 0, $primaryDark 8%, black);
      transition: opacity 0.5s ease-in-out;
  }

      &.light::before {
        opacity: 0;
    }

    &.light {
        background: linear-gradient($secondaryLight 0, $secondaryLight 8%, $secondary);
        color: $primaryDark;

        .shootingStar-container {
          display: none;
        }

        .rotatingSun-container {
          display: block;
        }

        .container {
          .left-container{
            background-color: $secondary;
            border-color: $secondaryDark;
            box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);

            .cloud {
              color: white;
            }

            .tag {
              color: $primaryDark;
            }
          }

          .right-container{
            h2 {
              color: $primaryLight;
            }
          }
        }
    }

    .container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10%;
        z-index: 1;

        .left-container {
            position: relative;
            width: 400px;
            max-width: 400px;
            height: 400px;
            max-height: 400px;
            border-radius: 50%;
            border: 10px solid $primaryLight;
            background-color: $primary;
            box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            overflow: hidden;

            @media screen and (max-width: 1024px) {
                width: 300px;
                max-width: 300px;
                height: 300px;
                max-height: 300px;
            }

            #marmot {
                width: 100%;
                height: 100%;
                z-index: 1;

                &:hover {
                    animation: animateMarmote 0.3s infinite;
                }
            }

            @keyframes animateMarmote {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translateY(5px);
                }

                100% {
                    transform: translate(0, 0);
                }
            }

            .cloud {
                position: absolute;
                color: #d9d9d9;
                transform: translateY(-200%);
            }

            .hover {
                animation: animateCloud 2.5s linear infinite;
            }

            .cloud1 {
                left: 10%;
                font-size: 5rem;
            }

            .cloud2 {
                left: 35%;
                font-size: 3rem;
                animation-delay: -1.8s;
            }

            .cloud3 {
                right: 10%;
                font-size: 6rem;
                animation-delay: -0.4s;
            }

            @keyframes animateCloud {
                0% {
                    transform: translateY(-100%);
                }

                100% {
                    transform: translateY(800%);
                }
            }
            .tag{
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translateX(-50%);
                font-size: .7rem;
                font-weight: 600;
                color: $secondary;
                animation: tagBlink 1s linear infinite;
            }

            @keyframes tagBlink {
                0% {
                    opacity: 0;
                }

                50% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }
        }

        .right-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: left;
            gap: 20px;
            width: 25rem;

            h1 {
                font-size: 3rem;
                font-weight: 600;
                color: inherit;
                @media screen and (max-width: 1024px){
                    font-size: 2.5rem;
                }
            }

            h2 {
                font-size: 2.5rem;
                font-weight: 600;
                color: $secondary;
                @media screen and (max-width: 1024px){
                    font-size: 2rem;
                }
            }
        }
    }
}