@import '../App.scss';

nav {
  background-color: $primaryDark;
  color: white;
  padding: 1rem 3rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-height: 8vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: all 0.5s ease-in-out;

  &.light {
    background-color: $secondaryLight;
    color: $primaryDark;

    .right{
      .nav-links{
          #work-list{
            .dropdown-works{
              .dropdown-content{
                >ul{
                  background-color: $secondary;
                  >li{
                    &:hover{
                      background-color: $secondaryLight;
                    }
                  }
              }
            }
          }
        }
    }
  }

  }

  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    .nav-links {
      list-style: none;
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      li {
        width: 4rem;
        text-align: center;

        a{
          text-decoration: none;
          color: inherit;
        }

        &:nth-child(2) {
          width: 4.5rem;
        }

        &:hover {
          font-weight: bold;
          cursor: pointer;
        }
      }

      #work-list{
        position: relative;
        .dropdown-works{
          position: relative;
          display: inline-block;

          .dropdown-content{
            display: none;
            background-color: inherit;
            position: absolute;
            min-width: 10rem;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 100;
            padding-top: 0.5rem;
            margin: 0;
            height: auto;

            >ul{
              background-color: $primary;
              margin: 0;
              width: 100%;
              display: block;

              >li{
                width: 100%;
                padding: 0 1rem;
                margin: 0;
                list-style: none;
                line-height: 2;
                font-size: 0.9rem;
                font-weight: 400;
                color: inherit;
                text-decoration: none;
                text-align: left;

                &:hover{
                  background-color: $primaryLight;
                }
              }
            }
          }

          &:hover{
            .dropdown-content{
              display: block;
            }
          }
        }
      }
    }
  }
};
