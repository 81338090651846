$primary: #19376d;
$primaryDark: #0b2447;
$primaryLight: #576cbc;
$secondary: #a5d7e8;
$secondaryDark: #7db9d9;
$secondaryLight: #cbe4f5;

@mixin circle($size) {
    width: $size;
    height: $size;
    border-radius: 50%;
}
