@import "../App.scss";

.shootingStar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.shooting-star {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-45deg);
    @include circle(5px);
    background-color: white;
    box-shadow: 0 0 2px 2px rgba($color: #FFF, $alpha: 0.3), 0 0 4px 4px rgba($color: #FFF, $alpha: 0.2), 0 0 8px 8px rgba($color: #FFF, $alpha: 0.1);
    animation: shootingStarAnimation 2s linear infinite;

    &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 200px;
        height: 1px;
        background: linear-gradient(90deg, #ffffff, transparent);
    }

}


@keyframes shootingStarAnimation {
    0% {
        transform: translate(0, 0) rotate(-45deg);
        opacity: 1;
    }

    10% {
        transform: translate(-100px, 100px) rotate(-45deg);
        opacity: 1;
    }

    70% {
        transform: translate(-900px, 900px) rotate(-45deg);
        opacity: 1;
    }

    100% {
        transform: translate(-1500px, 1500px) rotate(-45deg);
        opacity: 0;
    }
}