@import "../App.scss";

.switch {
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
      visibility: hidden;
      &:checked ~ .toggle {
        background-color: $primary;
        &:before {
          transform: translateX(20px);
          background-color: $secondaryLight;
          background-image: url("../img/sun-regular.svg");
        }
      }
    }
    .toggle{
      position: relative;
      display: block;
      width: 40px;
      height: 20px;
      background-color: $secondaryLight;
      border-radius: 20px;
      cursor: pointer;
      overflow: hidden;
      transition: ease-in 0.5s;

      &:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background-color: $primary;
        background-image: url("../img/moon-regular.svg");
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
        transition: ease-in 0.5s;
      }
    }
  }
}